/*
  Imports
*/
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
  Imports:
    Our Imports
    Components and Settings
*/

import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from '../../components/Page';
import PlayersList from './tables/PlayersList';
import userService from 'src/services/UserService';
/*
  Main Working
*/
export default () => {
  /*
  States, Params, Navigation, Query, Variables.
  */
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  /*
Handlers, Functions
*/
  const getData = () => {
    setLoading(true);
    userService
      .getPlayers()
      .then((u) => {
        setData(u);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => setLoading(false));
  };

  const pageName = 'Players';

  /*
  Use Effect Hooks.
*/

  useEffect(getData, []);

  /*
  Main Design.
  */
  return (
    <Page title={pageName}>
      <Container>
        <ListPageTitle>{pageName}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <PlayersList data={data} />
          </>
        )}
      </Container>
    </Page>
  );
};
