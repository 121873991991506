/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Checkbox
} from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import Dialog from 'src/components/misc/alerts/Dialog';
import { defaultPerPage, rowsPerPageList } from 'src/config/settings';
import paymentService from 'src/services/PaymentsService';
import palette from 'src/theme/palette';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../../components/misc/table';
import Scrollbar from '../../../components/Scrollbar';
import stripePaymentService from 'src/services/StripePaymentsService';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                element.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.paymentAmount).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.userID.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data, getData, setLoading, setError, stripe }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'email', label: 'Email', align: 'left' },
        { id: 'paymentAmount', label: 'Amount', align: 'center' },
        { id: 'userID', label: 'User ID', align: 'left' }
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[0].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(defaultPerPage);

    const [showDialog, setshowDialog] = useState(-1);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;
    /*
    Handlers, Functions
  */

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data?.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleClick = (event, name) => {
        event.preventDefault();
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleMoreMenuCell = (event) => {
        event.preventDefault();
    };

    const handlePay = () => {
        setLoading(true);
        paymentService
            .pay(selected)
            .then(getData)
            .catch((error) => {
                console.error('error', error.response.data.data);
                if (error.response.data.data) setError('Some of the payments failed to be paid');
                else setError('Something went wrong please try again');
            });
    };

    const handlePayStripe = () => {
        setLoading(true);
        stripePaymentService
            .pay(selected)
            .then(getData)
            .catch((error) => {
                console.error('error', error.response.data);
                if (error.response.data) setError(error.response.data.message);
                else setError('Something went wrong please try again');
            });
    };

    const openPayDialog = () => {
        setshowDialog(1);
    };

    const handleClose = () => {
        setshowDialog(-1);
    };

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    handleEvent={openPayDialog}
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TableHeader}
                                rowCount={data?.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                hideCheckBoxes={false}
                            />
                            <TableBody>
                                {filteredUsers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const { id, paymentAmount, email, userID } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                style={{
                                                    backgroundColor: palette.background.hover,
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onClick={(event) => handleClick(event, id)}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {email}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {stripe ? `€` : `$`}
                                                    {paymentAmount}
                                                </TableCell>
                                                <TableCell align="left">{userID}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageList}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            <Dialog
                warning
                buttonText={'Close'}
                buttonText2={'Pay'}
                openDialog={showDialog == 1}
                handleButton={handleClose}
                handleButton2={stripe ? handlePayStripe : handlePay}
            >
                {`Are you sure you want to confirm ${stripe ? 'stripe ' : ''} payouts?`}
            </Dialog>
        </>
    );
};
