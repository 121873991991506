import GenericService from './GenericService';
import qs from 'qs';

class StripePaymentService extends GenericService {
    getAll = () => this.get(`/stripe/`);
    pay = (ids) => this.post(`/stripe/payments`, { ids });
    paymentLink = () => this.get(`/stripe/paymentLink`);


    cancelPayment = (id) => this.post(`/stripe/payments/cancel/${id}`);
    getPayouts = (status) => this.get(`/stripe/payments/payouts${status ? `?status=${status}` : ''}`);

}

const stripePaymentService = new StripePaymentService();

export default stripePaymentService;
