import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
import { LogoImage } from '../config/settings';
import { RouteLandingPage } from '../config/routes';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

Logo.propTypes = {
    sx: PropTypes.object
};

export default function Logo({ sx }) {
    const navigate = useNavigate();

    return (
        <Box
            margin="auto"
            component="img"
            src={LogoImage}
            sx={{ width: 180, height: 'auto', cursor: 'pointer', ...sx }}
            onClick={() => navigate(RouteLandingPage)}
        />
    );
}
