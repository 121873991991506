export const TITLE = 'Trivib Payments';
export const rowsPerPageList = [10, 30, 50, 100, 500];
export const defaultPerPage = 100;
// export const BackendURL = 'http://54.208.112.242:1337/'; //Hosting
// export const BackendURL = 'http://localhost:1337/'; //Local
export const BackendURL = 'https://server.trivib.com/'; //Production
export const BackendURLAPI = BackendURL + 'api/';

export const InternetCheckInterval = 5 * 60 * 1000;

export const LogoImage = '/static/trivia-payments.png';
export const FaviconImage = LogoImage;
export const UserLoginImage = '/static/illustrations/illustration_login.png';
export const Image404 = '/static/illustrations/404.png';
export const Image401 = '/static/illustrations/401.webp';
