import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled';

import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import password1Icon from '@iconify/icons-wpf/password1';
import moneyBag from '@iconify/icons-fluent/money-hand-24-filled';
import historyIcon from '@iconify/icons-gridicons/history';
import accountLogout from '@iconify/icons-ri/logout-box-line';
import addCircle16Filled from "@iconify/icons-fluent/add-circle-16-filled";

export const SearchIcon = searchFill;
export const SuccessDialogIcon = checkCircle;
export const ErrorDialogIcon = closeCircle;
export const WarningDialogIcon = exclamationCircle;
export const ShowPasswordIcon = eyeOffFill;
export const HidePasswordIcon = eyeFill;
export const PasswordIcon = password1Icon;

export const PaymentsIcon = moneyBag;
export const PastPayoutsIcon = historyIcon;
export const LogoutIcon = accountLogout;
export const UsersIcon = peopleFill;
export const AddIcon = addCircle16Filled;

