import { Stack, Typography } from '@mui/material';

export default ({ children }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                {children}
            </Typography>
        </Stack>
    );
};
