import { Outlet, useNavigate } from 'react-router-dom';
// material
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import { useState } from 'react';
import Auth from 'src/components/authentication/login/Auth';
import { RouteAdminDashboard } from 'src/config/routes';
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout({ loginPage }) {
    const navigate = useNavigate();

    const [showPage, setShowPage] = useState(!loginPage);

    const handleOnAuth = (pass) => {
        if (!pass) return setShowPage(true);
        if (loginPage) return navigate(RouteAdminDashboard);
    };

    return (
        <Auth onAuth={handleOnAuth}>
            {showPage && (
                <>
                    <HeaderStyle>
                        <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none' } }}>
                            <Logo />
                        </Box>
                    </HeaderStyle>
                    <br></br>
                    <Outlet />
                </>
            )}
        </Auth>
    );
}
