import GenericService from './GenericService';
import qs from 'qs';

class PaymentService extends GenericService {
    getAll = () => this.get(`/payments`);
    pay = (ids) => this.post(`/payments`, { ids });

    getPast = (pagination) => this.get(`/payments/past?${qs.stringify(pagination)}`);
}

const paymentService = new PaymentService();

export default paymentService;
