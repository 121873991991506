/*
  Imports
*/
import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoOnlyBody from 'src/components/misc/LogoOnlyBody';
import { RouteAdminDashboard } from 'src/config/routes';
import { UserLoginImage } from 'src/config/settings';
import userService from 'src/services/UserService';
import { RootStyle } from 'src/theme/logo-only-pages';
import palette from 'src/theme/palette';
import LoginForm from './forms/LoginForm';

/*
  Main Working
*/
export default ({ user }) => {
    const loginDisplay = 'Admin';
    const image = UserLoginImage;

    const navigate = useNavigate();

    useEffect(() => {
        const loggedIn = userService.getLoggedInUser();
        if (!loggedIn) return;
        navigate(RouteAdminDashboard);
    }, [user]);

    return (
        <RootStyle title={loginDisplay}>
            <LogoOnlyBody image={image}>
                <Stack sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        {loginDisplay} Login
                    </Typography>
                    <Typography sx={{ color: palette.text.secondary }}>
                        Enter Your Login Details Below.
                    </Typography>
                </Stack>
                <LoginForm />
            </LogoOnlyBody>
        </RootStyle>
    );
};
