/*
  Imports
*/
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';
import { filter } from 'lodash';
import { useState } from 'react';
import { defaultPerPage, rowsPerPageList } from 'src/config/settings';
import palette from 'src/theme/palette';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../../components/misc/table';
import Scrollbar from '../../../components/Scrollbar';
import Label from 'src/components/misc/Label';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                element.email?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.id?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'id', label: 'User ID', align: 'left' },

        { id: 'email', label: 'Email', align: 'left' },
        { id: 'name', label: 'Name', align: 'left' },
        { id: 'totalWithdraw', label: 'Withdrawn', align: 'center' },
        { id: 'totalDeposit', label: 'Deposited', align: 'center' },
        { id: 'netAmount', label: 'Balance', align: 'center' }
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[0].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(defaultPerPage);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;
    /*
    Handlers, Functions
  */

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TableHeader}
                                rowCount={data.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {filteredUsers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const {
                                            id,
                                            name,
                                            email,
                                            totalDeposit,
                                            totalWithdraw,
                                            netAmount
                                        } = row;
                                        const isItemSelected = selected.indexOf(id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                style={{
                                                    backgroundColor: palette.background.hover,
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                <TableCell padding="checkbox"></TableCell>

                                                <TableCell align="left">
                                                    <Typography variant="subtitle2" noWrap>
                                                        {id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">{email}</TableCell>
                                                <TableCell align="left">{name}</TableCell>
                                                <TableCell align="center">
                                                    ${totalWithdraw}
                                                </TableCell>
                                                <TableCell align="center">
                                                    ${totalDeposit}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Label
                                                        variant="ghost"
                                                        color={
                                                            netAmount > 0
                                                                ? 'success'
                                                                : netAmount < 0
                                                                ? 'error'
                                                                : 'info'
                                                        }
                                                    >
                                                        ${Math.abs(netAmount)}
                                                    </Label>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageList}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
        </>
    );
};
