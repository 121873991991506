import { Icon } from '@iconify/react';
import { LogoutIcon, PastPayoutsIcon, PaymentsIcon, UsersIcon } from 'src/config/icons';

import { RouteAdminDashboard, RouteLogout, RoutePastPayments, RoutePlayers } from 'src/config/routes';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = {
    admin: [
        {
            title: 'Requested Payouts',
            path: RouteAdminDashboard,
            icon: getIcon(PaymentsIcon)
        },
        {
            title: 'Players',
            path: RoutePlayers,
            icon: getIcon(UsersIcon)
        },
        {
            title: 'Past Payouts',
            path: RoutePastPayments,
            icon: getIcon(PastPayoutsIcon)
        }
    ],

    bottom: [
        {
            title: 'logout',
            path: RouteLogout,
            icon: getIcon(LogoutIcon)
        }
    ]
};
