import { Icon } from '@iconify/react';
// material
import {
    Box,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MoveIcon, PaymentsIcon, SearchIcon } from 'src/config/icons';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

// ----------------------------------------------------------------------

export default function UserListToolbar({
    numSelected,
    filterName,
    onFilterName,
    handleEvent,
    handleSearch
}) {
    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.light'
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle2">
                    {numSelected} selected
                </Typography>
            ) : (
                <SearchStyle
                    value={filterName}
                    onChange={onFilterName}
                    onKeyDown={handleSearch}
                    placeholder="Search ..."
                    startAdornment={
                        <InputAdornment position="start">
                            <Box
                                component={Icon}
                                icon={SearchIcon}
                                sx={{ color: 'text.disabled' }}
                            />
                        </InputAdornment>
                    }
                />
            )}

            {numSelected > 0 && (
                <Tooltip title="Pay">
                    <IconButton onClick={handleEvent}>
                        <Icon icon={PaymentsIcon} />
                    </IconButton>
                </Tooltip>
            )}
        </RootStyle>
    );
}
