/*
  Imports
*/
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from 'src/components/misc/alerts/Dialog';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
  Imports:
    Our Imports
    Components and Settings
*/

import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentService from 'src/services/PaymentsService';

import Page from '../../components/Page';
import PaymentsList from './tables/PaymentsList';
import stripePaymentService from 'src/services/StripePaymentsService';
import { AddIcon } from 'src/config/icons';
import FloatingButton from 'src/components/misc/FloatingButton';

/*
  Main Working
*/
export default () => {
    /*
    States, Params, Navigation, Query, Variables.
        */

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [stripeData, setStripeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [link, setLink] = useState();

    /*
  Handlers, Functions
    */
    const getData = () => {
        setLoading(true);
        paymentService
            .getAll()
            .then((u) => {
                setData(u);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => setLoading(false));
    };

    const getStripeData = () => {
        setLoading(true);
        stripePaymentService
            .getAll()
            .then((u) => {
                setStripeData(u);
            })
            .catch((error) => {
                if (error.response) if (error.response.status === 401) navigate('/401');
                navigate('/404');
            })
            .finally(() => setLoading(false));
    };

    const getPaymentLink = () => {
        stripePaymentService
        .paymentLink()
        .then(res => {
            setLink(res?.url)
            setOpenDialog(true);
        })
    }

    const handleCloseError = () => {
        setError(false);
        getStripeData();
        getData();
    };
    const pageName = 'Payouts';

    /*
        Use Effect Hooks.
    */

    useEffect(() =>{
        getData(),
        getStripeData()
    }
    , []);

    /*
    Main Design.
        */
    return (
        <Page title={pageName}>
            <Container>
                <ListPageTitle>{pageName}</ListPageTitle>
                {loading ? (
                    <CenterLoading />
                ) : (
                    <>
                        <PaymentsList
                            data={data}
                            getData={getData}
                            setLoading={setLoading}
                            setError={setError}
                        />
                        <br/><br/>
                        <ListPageTitle>Stripe Pending Payouts</ListPageTitle>
                        <PaymentsList
                            data={stripeData}
                            getData={getStripeData}
                            setLoading={setLoading}
                            setError={setError}
                            stripe={true}
                        />
                        <FloatingButton
                            tooltip="add funds to stripe"
                            onClick={getPaymentLink}
                            bottomSpacing={(theme) => theme.spacing(2)}
                            icon={AddIcon}
                        />
                    </>
                )}
                <Dialog
                    error
                    buttonText={'Close'}
                    openDialog={!!error}
                    handleButton={handleCloseError}
                >
                    {error}
                </Dialog>
                <Dialog
                    buttonText={'Cancel'}
                    buttonText2={'Open Link'}
                    openDialog={openDialog}
                    handleButton={()=>setOpenDialog(false)}
                    handleButton2={()=>{
                        window.open(link, "_blank");
                        setOpenDialog(false)
                    }}
                >
                    {"Add balance to you stripe account"}
                </Dialog>
            </Container>
        </Page>
    );
};
