/*
  Imports
*/
import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';

/*
  Imports:
    Our Imports
    Components and Settings
*/

import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentService from 'src/services/PaymentsService';

import Page from '../../components/Page';
import PastPaymentsList from './tables/PastPaymentsList';
import stripePaymentService from 'src/services/StripePaymentsService';
import StripePaymentsList from './tables/StripePaymentsList';

/*
  Main Working
*/
export default () => {
  /*
  States, Params, Navigation, Query, Variables.
  */
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [stripeData, setStripeData] = useState([]);
  const [stripeFailedData, setStripeFailedData] = useState([]);
  const [stripeCanceledData, setStripeCanceledData] = useState([]);
  const [stripePaidData, setStripePaidData] = useState([]);


  const [loading, setLoading] = useState(true);

  /*
Handlers, Functions
*/
  const getData = () => {
    setLoading(true);
    paymentService
      .getPast()
      .then((u) => {
        setData(u);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => setLoading(false));
  };

  const getStripeData = () => {
    setLoading(true);
    stripePaymentService
      .getPayouts()
      .then((u) => {
        const paidPayouts = u.filter(pay=>pay.status === 'paid');
        const failedPayouts = u.filter(pay=>pay.status === 'failed');
        const canceledPayouts = u.filter(pay=>pay.status === 'canceled');
        setStripeFailedData(failedPayouts);
        setStripePaidData(paidPayouts);
        setStripeCanceledData(canceledPayouts);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => setLoading(false));
  };

  const pageName = 'Past Payments';

  /*
  Use Effect Hooks.
*/

  useEffect(()=>{
    getData(), 
    getStripeData()
  }, []);

  /*
  Main Design.
  */
  return (
    <Page title={pageName}>
      <Container>
        <ListPageTitle>{pageName}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <PastPaymentsList data={data} />
            <br/><br/>
            <ListPageTitle>Stripe Paid Payments</ListPageTitle>
            <StripePaymentsList data={stripePaidData} />
            <br/><br/>
            <ListPageTitle>Stripe Canceled Payments</ListPageTitle>
            <StripePaymentsList data={stripeCanceledData} />
            <br/><br/>
            <ListPageTitle>Stripe Failed Payments</ListPageTitle>
            <StripePaymentsList data={stripeFailedData} />

          </>
        )}
      </Container>
    </Page>
  );
};
